// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  surcussale: getIcon('ic_surcussale'),
  analytics: getIcon('ic_analytics'),
  borlette: getIcon('ic_borlette'),
  limite: getIcon('ic_limite'),
  tirage: getIcon('ic_tirage'),
  rapport: getIcon('ic_rapport'),
  supervisor: getIcon('ic_supervisor'),
  agent: getIcon('ic_agent')
};

export const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      // MANAGEMENT : BOLETTE
      {
        title: 'borlette',
        path: PATH_DASHBOARD.borlette?.root,
        icon: ICONS.borlette,
        children: [
          { title: 'blocage boule', path: PATH_DASHBOARD.borlette?.blocage_boule },
          { title: 'statistiques', path: PATH_DASHBOARD.borlette?.statistique },
          { title: 'listes lottos', path: PATH_DASHBOARD.borlette?.lotto },
          { title: 'listes fiches', path: PATH_DASHBOARD.borlette?.list }
        ]
      },
      // MANAGEMENT : CONFIGURATION
      {
        title: 'limite',
        path: PATH_DASHBOARD.configuration_borlette?.root,
        icon: ICONS.limite,
        children: [
          { title: 'Limite Jeu', path: PATH_DASHBOARD.configuration_borlette?.type_game },
          { title: 'Limite Jeu Par Agent', path: PATH_DASHBOARD.configuration_borlette?.type_game_agent },
          { title: 'Limite Boule', path: PATH_DASHBOARD.configuration_borlette?.type_boule },
          { title: 'Limite Boule Par Agent', path: PATH_DASHBOARD.configuration_borlette?.type_boule_agent }
        ]
      },
      // MANAGEMENT : TIRAGE
      {
        title: 'tirage',
        path: PATH_DASHBOARD.tirage?.root,
        icon: ICONS.tirage,
        children: [
          { title: 'Ajouter Tirage', path: PATH_DASHBOARD.tirage?.newTirage },
          { title: 'listes Tirage', path: PATH_DASHBOARD.tirage?.list },
          { title: 'configuration', path: PATH_DASHBOARD.tirage?.configuration },
          { title: 'type tirage', path: PATH_DASHBOARD.tirage?.info_tirage }
        ]
      },
      // MANAGEMENT : RAPPORT
      { title: 'rapports', path: PATH_DASHBOARD.rapport, icon: ICONS.analytics }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : SUPERVISOR
      {
        title: 'superviseur',
        path: PATH_DASHBOARD.supervisor?.root,
        icon: ICONS.supervisor,
        children: [
          { title: 'Ajouter', path: PATH_DASHBOARD.supervisor?.newSupervisor },
          { title: 'listes', path: PATH_DASHBOARD.supervisor?.list }
        ]
      },

      // MANAGEMENT : SURCUSSALE
      {
        title: 'surcussale',
        path: PATH_DASHBOARD.surcussale?.root,
        icon: ICONS.surcussale,
        children: [
          { title: 'Ajouter', path: PATH_DASHBOARD.surcussale?.newSurcussale },
          { title: 'listes', path: PATH_DASHBOARD.surcussale?.list }
        ]
      },

      // MANAGEMENT : AGENT
      {
        title: 'agent',
        path: PATH_DASHBOARD.agent?.root,
        icon: ICONS.agent,
        children: [
          { title: 'Ajouter', path: PATH_DASHBOARD.agent?.newAgent },
          { title: 'listes', path: PATH_DASHBOARD.agent?.list }
        ]
      },

      // MANAGEMENT : COMPANY
      {
        title: 'mon compte',
        icon: ICONS.user,
        path: PATH_DASHBOARD.user?.root,
        children: [
          { title: 'Editer Profile', path: PATH_DASHBOARD.user?.account },
          { title: 'Mes tracabilites', path: PATH_DASHBOARD.user?.tracabilite_me },
          { title: 'Tracabilites Agents', path: PATH_DASHBOARD.user?.tracabilite_agent },
          { title: 'Tracabilites Superviseurs', path: PATH_DASHBOARD.user?.tracabilite_supervisor }
        ]
      }
    ]
  }
];

export const sidebarConfigSupervisor = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      // MANAGEMENT : BOLETTE
      {
        title: 'borlette',
        path: PATH_DASHBOARD.borlette?.root,
        icon: ICONS.borlette,
        children: [
          { title: 'blocage boule', path: PATH_DASHBOARD.borlette?.blocage_boule },
          { title: 'statistiques', path: PATH_DASHBOARD.borlette?.statistique },
          { title: 'listes lottos', path: PATH_DASHBOARD.borlette?.lotto },
          { title: 'listes fiches', path: PATH_DASHBOARD.borlette?.list }
        ]
      },
      // MANAGEMENT : CONFIGURATION
      {
        title: 'limite',
        path: PATH_DASHBOARD.configuration_borlette?.root,
        icon: ICONS.limite,
        children: [
          { title: 'Limite Jeu', path: PATH_DASHBOARD.configuration_borlette?.type_game },
          { title: 'Limite Jeu Par Agent', path: PATH_DASHBOARD.configuration_borlette?.type_game_agent },
          { title: 'Limite Boule', path: PATH_DASHBOARD.configuration_borlette?.type_boule },
          { title: 'Limite Boule Par Agent', path: PATH_DASHBOARD.configuration_borlette?.type_boule_agent }
        ]
      },
      // MANAGEMENT : TIRAGE
      {
        title: 'tirage',
        path: PATH_DASHBOARD.tirage?.root,
        icon: ICONS.tirage,
        children: [
          { title: 'listes Tirage', path: PATH_DASHBOARD.tirage?.list },
          { title: 'configuration', path: PATH_DASHBOARD.tirage?.configuration },
          { title: 'type tirage', path: PATH_DASHBOARD.tirage?.info_tirage }
        ]
      },
      // MANAGEMENT : RAPPORT
      { title: 'rapports', path: PATH_DASHBOARD.rapport, icon: ICONS.analytics }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : AGENT
      {
        title: 'agent',
        path: PATH_DASHBOARD.agent?.root,
        icon: ICONS.agent,
        children: [
          { title: 'Ajouter', path: PATH_DASHBOARD.agent?.newAgent },
          { title: 'listes', path: PATH_DASHBOARD.agent?.list }
        ]
      },

      // MANAGEMENT : COMPANY
      {
        title: 'mon compte',
        icon: ICONS.user,
        path: PATH_DASHBOARD.user?.root,
        children: [
          { title: 'Mes tracabilites', path: PATH_DASHBOARD.user?.tracabilite_supervisor },
          { title: 'Tracabilites Agents', path: PATH_DASHBOARD.user?.tracabilite_agent }
        ]
      }
    ]
  }
];

export default { sidebarConfig, sidebarConfigSupervisor };
