import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------
/* eslint-disable */

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="100" height="100" viewBox="0 0 303 240"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0,200) scale(0.100000,-0.100000)"
          fill={PRIMARY_MAIN} stroke="none">
          <path d="M352 1394 c-104 -28 -208 -118 -253 -220 -27 -60 -29 -168 -5 -240
22 -68 107 -161 183 -202 75 -40 153 -52 336 -52 l147 0 0 235 0 235 -175 0
-175 0 0 -55 0 -55 65 0 66 0 -3 -77 c-3 -75 -4 -78 -28 -82 -54 -7 -125 18
-165 59 -33 34 -39 47 -43 95 -4 47 -1 61 21 94 46 69 88 81 275 81 l162 0 0
100 0 100 -177 -1 c-121 0 -195 -5 -231 -15z"/>
          <path d="M820 995 l0 -415 115 0 115 0 0 415 0 415 -115 0 -115 0 0 -415z" />
          <path d="M1130 1311 l0 -99 29 -7 c46 -10 109 -70 121 -116 22 -81 -36 -180
-115 -195 l-35 -6 0 -103 0 -102 52 8 c69 11 101 24 171 72 138 92 192 271
128 418 -41 95 -151 187 -256 214 -100 25 -95 29 -95 -84z"/>
          <path d="M1544 1394 c3 -9 6 -54 6 -100 l0 -84 285 0 285 0 0 100 0 100 -291
0 c-261 0 -291 -2 -285 -16z"/>
          <path d="M1549 1146 c-2 -2 -3 -108 -1 -235 l3 -231 109 0 110 0 0 135 0 135
175 0 175 0 0 100 0 100 -283 0 c-156 0 -286 -2 -288 -4z"/>
        </g>
      </svg>
    </Box>
  );
}
