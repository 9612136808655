import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'rapports', element: <Rapport /> },
        {
          path: 'borlette',
          children: [
            { element: <Navigate to="/dashboard/borlette/list-fiches" replace /> },
            { path: 'blocage-boule', element: <Boule /> },
            { path: 'blocage-boule/new', element: <BouleCreate /> },
            { path: 'blocage-boule/:bouleId/edit', element: <BouleCreate /> },
            { path: 'statistiques', element: <Statistique /> },
            { path: 'lottos', element: <Lotto /> },
            { path: 'list-fiches', element: <Fiche /> }
          ]
        },
        {
          path: 'borlette-configuration',
          children: [
            { element: <Navigate to="/dashboard/borlette-configuration/limite-game" replace /> },
            { path: 'limite-game', element: <TypeGame /> },
            { path: 'limite-game/new', element: <TypeGameCreate /> },
            { path: 'limite-game/:typeGameId/edit', element: <TypeGameCreate /> },
            { path: 'limite-game-per-agent', element: <TypeGameAgent /> },
            { path: 'limite-game-per-agent/new', element: <TypeGameAgentCreate /> },
            { path: 'limite-game-per-agent/:typeGameAgentId/edit', element: <TypeGameAgentCreate /> },
            { path: 'limite-boule', element: <TypeBoule /> },
            { path: 'limite-boule/new', element: <TypeBouleCreate /> },
            { path: 'limite-boule/:typeBouleId/edit', element: <TypeBouleCreate /> },
            { path: 'limite-boule-per-agent', element: <TypeBouleAgent /> },
            { path: 'limite-boule-per-agent/new', element: <TypeBouleAgentCreate /> },
            { path: 'limite-boule-per-agent/:typeBouleAgentId/edit', element: <TypeBouleAgentCreate /> }
          ]
        },
        {
          path: 'tirage',
          children: [
            { element: <Navigate to="/dashboard/tirage/list" replace /> },
            { path: 'list', element: <Tirage /> },
            { path: 'new', element: <TirageCreate /> },
            { path: ':tirageId/edit', element: <TirageCreate /> },
            { path: 'configuration', element: <TirageConf /> },
            { path: 'configuration-new', element: <TirageConfCreate /> },
            { path: 'configuration/:configurationId/edit', element: <TirageConfCreate /> },
            { path: 'info', element: <InfoTirage /> },
            { path: 'infotirage-new', element: <InfoTirageCreate /> },
            { path: 'info/:infoTirageId/edit', element: <InfoTirageCreate /> }
          ]
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/account" replace /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'tracabilite-agent', element: <TracabiliteAgent /> },
            { path: 'tracabilite-company/me', element: <TracabiliteCompany /> },
            { path: 'tracabilite-supervisor', element: <TracabiliteSupervisor /> }
          ]
        },
        {
          path: 'company',
          children: [
            { element: <Navigate to="/dashboard/agent/list" replace /> },
            { path: 'list', element: <Agent /> },
            { path: 'new', element: <AgentCreate /> },
            { path: ':agentId/edit', element: <AgentCreate /> }
          ]
        },
        {
          path: 'agent',
          children: [
            { element: <Navigate to="/dashboard/agent/list" replace /> },
            { path: 'list', element: <Agent /> },
            { path: 'new', element: <AgentCreate /> },
            { path: ':agentId/edit', element: <AgentCreate /> }
          ]
        },
        {
          path: 'supervisor',
          children: [
            { element: <Navigate to="/dashboard/supervisor/list" replace /> },
            { path: 'list', element: <Supervisor /> },
            { path: 'new', element: <SupervisorCreate /> },
            { path: ':supervisorId/edit', element: <SupervisorCreate /> }
          ]
        },
        {
          path: 'surcussale',
          children: [
            { element: <Navigate to="/dashboard/surcussale/list" replace /> },
            { path: 'list', element: <Surcussale /> },
            { path: 'new', element: <SurcussaleCreate /> },
            { path: ':surcussaleId/edit', element: <SurcussaleCreate /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [{ path: '*', element: <Navigate to="/404" replace /> }]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/dashboard" replace /> },
        { path: '404', element: <NotFound /> },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const Agent = Loadable(lazy(() => import('../pages/dashboard/Agents')));
const AgentCreate = Loadable(lazy(() => import('../pages/dashboard/AgentCreate')));
const Supervisor = Loadable(lazy(() => import('../pages/dashboard/Supervisors')));
const SupervisorCreate = Loadable(lazy(() => import('../pages/dashboard/SupervisorCreate')));
const Lotto = Loadable(lazy(() => import('../pages/dashboard/Lottos')));
const Surcussale = Loadable(lazy(() => import('../pages/dashboard/Surcussales')));
const SurcussaleCreate = Loadable(lazy(() => import('../pages/dashboard/SurcussaleCreate')));
const Tirage = Loadable(lazy(() => import('../pages/dashboard/Tirages')));
const TirageCreate = Loadable(lazy(() => import('../pages/dashboard/TirageCreate')));
const Rapport = Loadable(lazy(() => import('../pages/dashboard/Rapports/RapportInit')));
const Boule = Loadable(lazy(() => import('../pages/dashboard/Boules')));
const BouleCreate = Loadable(lazy(() => import('../pages/dashboard/BouleCreate')));
const InfoTirage = Loadable(lazy(() => import('../pages/dashboard/InfoTirages')));
const InfoTirageCreate = Loadable(lazy(() => import('../pages/dashboard/InfoTirageCreate')));
const TypeGame = Loadable(lazy(() => import('../pages/dashboard/TypeGame')));
const TypeGameCreate = Loadable(lazy(() => import('../pages/dashboard/TypeGameCreate')));
const TypeBoule = Loadable(lazy(() => import('../pages/dashboard/TypeBoule')));
const TypeBouleCreate = Loadable(lazy(() => import('../pages/dashboard/TypeBouleCreate')));
const TypeGameAgent = Loadable(lazy(() => import('../pages/dashboard/TypeGameAgent')));
const TypeGameAgentCreate = Loadable(lazy(() => import('../pages/dashboard/TypeGameAgentCreate')));
const TypeBouleAgent = Loadable(lazy(() => import('../pages/dashboard/TypeBouleAgent')));
const TypeBouleAgentCreate = Loadable(lazy(() => import('../pages/dashboard/TypeBouleAgentCreate')));
const TirageConf = Loadable(lazy(() => import('../pages/dashboard/TirageConf')));
const TirageConfCreate = Loadable(lazy(() => import('../pages/dashboard/TirageConfCreate')));
const Statistique = Loadable(lazy(() => import('../pages/dashboard/Statistiques')));
const TracabiliteAgent = Loadable(lazy(() => import('../pages/dashboard/TracabiliteAgents')));
const TracabiliteSupervisor = Loadable(lazy(() => import('../pages/dashboard/TracabiliteSupervisors')));
const TracabiliteCompany = Loadable(lazy(() => import('../pages/dashboard/TracabiliteCompany')));
const Fiche = Loadable(lazy(() => import('../pages/dashboard/Fiches')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Main
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
